import React, { useContext, useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import API from '../API';
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Navbar from "../Components/Navbar";
import NavbarDashboard from "../Components/NavbarDashboard";


function ProfilePage(props) {
  const { serverUrl } = useContext(API)
  const [userData, setUserData] = useState({})
  const { accessToken, setIsLogged, setAccessToken, notify } = props;
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate()
  const [isEditingUsername, setIsEditingUsername] = useState(false);
  const [isEditingEmail, setIsEditingEmail] = useState(false);
  const [isEditingPassword, setIsEditingPassword] = useState(false);
    

  const handleUpdate = async (e) => {
    e.preventDefault();
  
    const postData = {
      username: username,
      email: email,
      password: password,
    };
  
    try {
      await axios.patch(`${serverUrl}/user/profile  `, postData, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      setUserData(postData);
      notify("Votre profil a bien été mis à jour", "success");
    } catch (error) {
      console.error(error);
      notify("Une erreur est survenue lors de la mise à jour du profil", "error");
    }
  
    setIsEditingUsername(false);
    setIsEditingEmail(false);
    setIsEditingPassword(false);
  };
  
  const handleEdit = () => {
    setIsEditingUsername(true);
    setIsEditingEmail(true);
    setIsEditingPassword(true);
  };

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const result = await axios(`${serverUrl}/user/profile`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          method: "POST",
        });
        setUserData(result.data.data);
      } catch (error) {
        setIsLogged(false);
        setAccessToken("");
        sessionStorage.removeItem("accessToken");
        notify(`Votre session a expirée, veuillez vous reconnecter.`, "error");
        navigate('/login');
      }
    }

    fetchUserProfile();
  }, [accessToken, navigate, notify, setAccessToken, setIsLogged, serverUrl])


  return (
    <>
      <Helmet>
        <title>Profil</title>
      </Helmet>
    <Navbar />
    <NavbarDashboard />
    <div className="profilePage">
      <h1 className="profilePage__title">Votre profil {userData.username}</h1>
      <form className="profilePage__form" action="">
        <div className="profilePage__form__container">
        <p className="profilePage__form__container__title">nom d'utilisateur:</p>
        {isEditingUsername ? (
          <input className="profilePage__form__container__input" type="text" value={username} placeholder={userData.username} onChange={(e) => setUsername(e.target.value)} />
        ) : (
          <span className="profilePage__form__container__data">{userData.username}</span>
        )}
        </div>
        <div className="profilePage__form__container">
        <p className="profilePage__form__container__title">email:</p>
        {isEditingEmail ? (
          <input className="profilePage__form__container__input" type="text" value={email} placeholder={userData.email} onChange={(e) => setEmail(e.target.value)} />
        ) : (
          <span className="profilePage__form__container__data">{userData.email}</span>
        )}
        </div>
        <div className="profilePage__form__container">
        <p className="profilePage__form__container__title">mot de passe: </p>
        {isEditingPassword ? (
          <input className="profilePage__form__container__input" type="password" value={password} placeholder="******" onChange={(e) => setPassword(e.target.value)} />
        ) : (
          <span className="profilePage__form__container__data">******</span>
        )}
        </div>
  
        {!isEditingUsername && !isEditingEmail && !isEditingPassword && (
          <button className="profilePage__form__button" onClick={handleEdit}>Modifier</button>
        )}
        {isEditingUsername || isEditingEmail || isEditingPassword ? (
          <button className="profilePage__form__button" onClick={handleUpdate}>Appliquer</button>
        ) : null}
      </form>
      </div>
    </>
  );
}

export default ProfilePage;

import axios from 'axios';
import React, { useEffect, useState, useContext } from 'react'
import { useNavigate } from 'react-router-dom';
import { Link } from "react-router-dom";
import API from '../API';



function Navbar(props) {
  const { serverUrl } = useContext(API);
  const [userData, setUserData] = useState({});
  const { accessToken, setIsLogged, setAccessToken, notify } = props;
  const navigate = useNavigate();

  //permet de se déconnecter

  const handleLogout = () => {
    setIsLogged(false);
    sessionStorage.clear();
    navigate('/');
  }

  //permet de récupérer les données de l'utilisateur connecté

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const result = await axios(`${serverUrl}/user/profile`, {
          headers: {
            "Authorization": `Bearer ${accessToken}`,
          },
          method: "POST",
        });
        setUserData(result.data.data);
      } catch (error) {
        setIsLogged(false);
        setAccessToken("");
        sessionStorage.removeItem("accessToken");
        notify(`Votre session a expirée, veuillez vous reconnecter.`, "error");
        navigate('/login');
      }
    }

    fetchUserProfile();
  }, [accessToken, navigate, notify, setAccessToken, setIsLogged, serverUrl])


  return (
    <nav className='navDashboard'>
        <h1 className='navDashboard__title'>Bienvenue {userData.username}</h1>
      <div className='navDashboard__links'>
          <Link className='navDashboard__links__link' to="/dashboard">Dashboard</Link>
          <Link className='navDashboard__links__link' to="/profil">Profil</Link>
          <button className='navDashboard__links__button' onClick={() => handleLogout()}>
            Deconnexion
            </button>
      </div>
    </nav>
  );
}

export default Navbar;

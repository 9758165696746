import React, { useState, useEffect, useContext, useRef } from 'react';
import axios from 'axios';
import FormData from 'form-data';
import Select from 'react-select';
import 'react-toastify/dist/ReactToastify.css';
import API from '../API';

function EnigmeEdit({ EnigmeId, notify, ...props }) {
    const { accessToken, setAccessToken } = props;
    const { serverUrl } = useContext(API);
    const [title, setTitle] = useState('');
    const [file, setFile] = useState(null);
    const [image, setImage] = useState([]);
    const [selectedImage, setSelectedImage] = useState([]);
    const [seeUploaded, setSeeUploaded] = useState(selectedImage.length > 0)
    const formData = new FormData();
    const [selectedEnigme, setSelectedEnigme] = useState();
    const [dropArea, setDropArea] = useState(null);
    const [answer, setAnswer] = useState('');
    const [text, setText] = useState('');
    const [desc, setDesc] = useState('');
    const [indice, setIndice] = useState('');
    const [selectedImageEnigmeId, setSelectedImageEnigmeId] = useState([]);

    useEffect(() => {
        const dropArea = document.querySelector(".drop_box");
        if (dropArea) {
            const dragText = dropArea.querySelector("header");

            const handleDragOver = (e) => {
                e.preventDefault();
                dropArea.classList.add("dragging");
                dragText.textContent = "Relâchez le fichier";
            };

            const handleDragLeave = () => {
                dropArea.classList.remove("dragging");
                dragText.textContent = "Déposez le fichier ici";
            };

            const handleDrop = (e) => {
                e.preventDefault();
                dropArea.classList.remove("dragging");
                dragText.textContent = "Déposez le fichier ici";
                const file = e.dataTransfer.files[0];
                setFile(file);
            };

            dropArea.addEventListener("dragover", handleDragOver);
            dropArea.addEventListener("dragleave", handleDragLeave);
            dropArea.addEventListener("drop", handleDrop);

            setDropArea(dropArea);
        }
    }, []);

    const handleDragOver = (e) => {
        e.preventDefault();
        dropArea.classList.add("dragging");
        dragText.textContent = "Relâchez le fichier";
    };

    const handleDragLeave = () => {
        dropArea.classList.remove("dragging");
        dragText.textContent = "Déposez le fichier ici";
    };

    const handleDrop = (e) => {
        e.preventDefault();
        dropArea.classList.remove("dragging");
        dragText.textContent = "Déposez le fichier ici";
        const file = e.dataTransfer.files[0];
        setFile(file);
    };

    let dragText = useRef(null);

    //Met à jour le fichier quand il est séléctionné

    const handleFileChange = (event) => {
        setFile(event.target.files[0]);
    };

    const handleImageSelect = (event) => {
        event.preventDefault();
        document.getElementById('image').click();
    };

    //Affiche le select si la checkbox est coché

    const handleCheckboxChange = () => {
        setSeeUploaded(prevSeeUploaded => !prevSeeUploaded);
    };


    //Fonction qui va être utilisé si une image doit être upload,
    //elle va utiliser l'API d'imgur pour récupérer un lien et le stocker dans la BDD

    const createImage = async () => {
        try {
            let imageUrl;

            //vérifie si la checkbox est coché, si oui il va récupérer l'id de l'image
            //pour sortir de la fonction
            if (seeUploaded === true) {
                imageUrl = selectedImage.map((id) => image.find((img) => img._id === id)._id
                );
                if (selectedImage.length === 0) {
                    // Si aucune image n'est sélectionnée, renvoyer null
                    return null;
                }

                imageUrl = selectedImage.map((id) => image.find((img) => img._id === id)._id);
                return imageUrl;

            } else {
                const response = await axios({
                    method: "post",
                    url: "https://api.imgur.com/3/image",
                    data: formData,
                    headers: {
                        Authorization: "Client-ID 41e0d680636e63d",
                        "Content-Type": "multipart/form-data",
                    },
                });

                //récupère l'URL De l'image uploadé et poste l'url de l'image dans la collection

                imageUrl = response.data.data.link;

                const imageResponse = await axios.post(
                    `${serverUrl}/image/create`,
                    {
                      url: imageUrl,
                    },
                    {
                      headers: {
                        Authorization: `Bearer ${accessToken}`,
                      },
                    }
                  );

                const imageId = imageResponse.data.image._id;

                return imageId;
            }

        } catch (error) {
            console.log(error);
        }
    };

    formData.append("image", file);

    //récupère toutes les données pour pouvoir les modifier

    useEffect(() => {
        axios.get(`${serverUrl}/image`)
            .then(response => {
                setImage(response.data.Images);

            })
            .catch(error => {
                console.error(error);
            });
    }, [serverUrl]);

    useEffect(() => {
        const handlEnigmeSelect = async (EnigmeId) => {
            try {
                const response = await axios.get(`${serverUrl}/enigme/${EnigmeId}`);
                if (response.status === 200) {
                    setSelectedEnigme(response.data.enigme);
                }
            } catch (error) {
                notify(`Une erreur est survenue ${error?.response?.data?.message || ""}`, "error");
            }
        };
        if (EnigmeId) {
            handlEnigmeSelect(EnigmeId);
        }
    }, [EnigmeId, serverUrl, notify]);

    useEffect(() => {
        if (selectedEnigme) {
            setTitle(selectedEnigme.title);
            setDesc(selectedEnigme.desc);
            setAnswer(selectedEnigme.answer);
            setText(selectedEnigme.text);
            setIndice(selectedEnigme.indice);
            setSelectedImage([selectedEnigme.image]);
            setSeeUploaded(selectedEnigme.image !== null);
            setSelectedImageEnigmeId([selectedEnigme.imageanswer]);
        }
    }, [selectedEnigme]);

    //va mettre à jour la BDD avec les nouvelles informations

    const handleUpdate = async (e) => {
        e.preventDefault();

        const imageId = await createImage();

        const postData = {
            title: title,
            image: imageId ? imageId : null,
            desc: desc,
            indice: indice,
            answer: answer,
            text: text,
            imageanswer: selectedImageEnigmeId ? selectedImageEnigmeId : null,
        };

        console.log(postData);

        try {
            if (!selectedEnigme) {
                notify("Aucun post sélectionné", "error");
                return;
            }

            const response = await axios.patch(`${serverUrl}/enigme/${selectedEnigme._id}`, postData, {
                headers: {
                    "Authorization": `Bearer ${accessToken}`,
                },
            });

            if (response.status === 200) {
                notify(`${response?.data?.message || ""}`, "success");
            }

        } catch (error) {
            setAccessToken("");
            sessionStorage.removeItem("accessToken");
            notify(`Une erreur est survenue ${error?.response?.data?.message || ""}`, "error");
            console.log(error);
        }
    };

    return (
        <>
            <form onSubmit={handleUpdate} className="form-container">
                <div>
                    <label htmlFor="title">Titre:</label>
                    <input type="text" id="title" value={title} onChange={(e) => setTitle(e.target.value)} />
                </div>
                <div>
                    <label htmlFor="description">Enigme:</label>
                    <textarea id="description" rows={10} value={desc} onChange={(e) => setDesc(e.target.value)} />
                </div>
                <div>
                    <label htmlFor="answer">Réponse:</label>
                    <textarea id="answer" rows={10} value={answer} onChange={(e) => setAnswer(e.target.value.toLowerCase())} />
                </div>
                <div>
                    <label htmlFor="indice">Indice:</label>
                    <textarea id="indice" rows={10} value={indice} onChange={(e) => setIndice(e.target.value)} />
                </div>
                <div>
                    <label htmlFor="text">Texte de la réponse :</label>
                    <textarea id="text" rows={10} value={text} onChange={(e) => setText(e.target.value)} />
                </div>
                <div className='select'>
                    <div className='checkbox-align'>
                        <label htmlFor="image" className='checkbox-align__label'>Choisir une image déjà importé:</label>
                        <label htmlFor="checkbox" className='toggle-switch'>
                            <input type="checkbox" name="seeUploadedCheckbox" id="seeUploadedCheckbox" className='toggle-input' onChange={handleCheckboxChange} checked={seeUploaded} />
                            <label htmlFor="seeUploadedCheckbox" className='toggle-label'></label>
                        </label>
                    </div>
                    {seeUploaded ? (
                        <Select
                            isMulti
                            value={selectedImage.map((id) => ({
                                value: id,
                                label: image && image.find((image) => image._id === id)?.url
                            }))}
                            options={image.map((image) => ({
                                value: image._id,
                                label: (
                                    <div>
                                        <img src={image.url} alt={image._id} style={{ height: "50px" }} />
                                        <span>{image.url}</span>
                                    </div>
                                )
                            }))}
                            formatOptionLabel={(option) => option.label}
                            onChange={(selectedOptions) =>
                                setSelectedImage(selectedOptions.map((option) => option.value))
                            }
                        />
                    ) : (<div className="container">
                        <div className="card">
                            <div
                                className="drop_box"
                                ref={dropArea}
                                onDragOver={handleDragOver}
                                onDragLeave={handleDragLeave}
                                onDrop={handleDrop}
                            >
                                <header ref={dragText}>
                                    <h4>Déposez votre fichier ici</h4>
                                </header>
                                {file && <p>Le fichier sélectionné est : {file.name}</p>}
                                <input type="file" hidden accept=".png,.jpeg,.gif" id="image" onChange={handleFileChange} />
                                <button className="btn" onClick={handleImageSelect}>Choisir une image</button>
                            </div>
                        </div>
                    </div>
                    )}

                </div>
                <div>
                    <label htmlFor="imageEnigme">Image de la réponse:</label>
                    <Select
                        isMulti
                        className="select"
                        value={selectedImageEnigmeId.map((id) => ({
                            value: id,
                            label: image && image.find((img) => img._id === id)?.url
                        }))}
                        options={image.map((img) => ({
                            value: img._id,
                            label: (
                                <div>
                                    <img src={img.url} alt={img._id} style={{ height: "50px" }} />
                                    <span>{img.url}</span>
                                </div>
                            )
                        }))}
                        formatOptionLabel={(option) => option.label}
                        onChange={(selectedOptions) =>
                            setSelectedImageEnigmeId(Object.values(selectedOptions).map((option) => option.value))
                        }

                    />
                </div>
                <button type="submit">Modifier l'énigme</button>
            </form>
        </>
    );
}

export default EnigmeEdit;


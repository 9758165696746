import React, { useEffect, useState, useContext, useRef } from "react";
import axios from "axios";
import Select from "react-select";
import API from '../API';


function AnnexeForm(props) {
    const { notify, accessToken, setAccessToken } = props;
    const [description, setDescription] = React.useState("");
    const [image, setImage] = React.useState("");
    const [seeUploaded, setSeeUploaded] = React.useState(false);
    const [file, setFile] = useState(null);
    const formData = new FormData();
    const [selectedImage, setSelectedImage] = useState([]);
    const { serverUrl } = useContext(API);
    const [dropArea, setDropArea] = useState(null);
    const [showFileInput, setShowFileInput] = useState(true);

    useEffect(() => {
        const dropArea = document.querySelector(".drop_box");
        if (dropArea) {
            const dragText = dropArea.querySelector("header");

            const handleDragOver = (e) => {
                e.preventDefault();
                dropArea.classList.add("dragging");
                dragText.textContent = "Relâchez le fichier";
            };

            const handleDragLeave = () => {
                dropArea.classList.remove("dragging");
                dragText.textContent = "Déposez le fichier ici";
            };

            const handleDrop = (e) => {
                e.preventDefault();
                dropArea.classList.remove("dragging");
                dragText.textContent = "Déposez le fichier ici";
                const file = e.dataTransfer.files[0];
                setFile(file);
            };

            dropArea.addEventListener("dragover", handleDragOver);
            dropArea.addEventListener("dragleave", handleDragLeave);
            dropArea.addEventListener("drop", handleDrop);

            setDropArea(dropArea);
        }
    }, []);

    const handleDragOver = (e) => {
        e.preventDefault();
        dropArea.classList.add("dragging");
        dragText.textContent = "Relâchez le fichier";
    };

    const handleDragLeave = () => {
        dropArea.classList.remove("dragging");
        dragText.textContent = "Déposez le fichier ici";
    };

    const handleDrop = (e) => {
        e.preventDefault();
        dropArea.classList.remove("dragging");
        dragText.textContent = "Déposez le fichier ici";
        const file = e.dataTransfer.files[0];
        setFile(file);
    };

    let dragText = useRef(null);

    //Met à jour le fichier quand il est séléctionné

    const handleFileChange = (event) => {
        setFile(event.target.files[0]);
    };

    const handleImageSelect = (event) => {
        event.preventDefault();
        document.getElementById('image').click();
    };
    //Affiche le select si la checkbox est coché

    const handleCheckboxChange = () => {
        setSeeUploaded(!seeUploaded);
        setShowFileInput(!showFileInput);
      };      

    //Fonction qui va être utilisé si une image doit être upload,
    //elle va utiliser l'API d'imgur pour récuperer un lien et le stocké dans la BDD

    const createImage = async () => {
        try {
            let imageUrl;

            //vérifie si la checkbox est coché, si oui il va récupérer l'id de l'image
            //pour sortir de la fonction

            if (seeUploaded === true) {
                imageUrl = selectedImage.map((id) => image.find((img) => img._id === id)._id
                );
                return imageUrl;

                //si la checkbox n'est pas coché il va prendre le fichier et l'upload sur imgur

            } else {
                const response = await axios({
                    method: "post",
                    url: "https://api.imgur.com/3/image",
                    data: formData,
                    headers: {
                        Authorization: "Client-ID 41e0d680636e63d",
                        "Content-Type": "multipart/form-data",
                    },
                });

                //récupère l'URL de l'image uploadé et poste l'url de l'image dans la collection image

                imageUrl = response.data.data.link;

                const imageResponse = await axios.post(
                    `${serverUrl}/image/create`,
                    {
                      url: imageUrl,
                    },
                    {
                      headers: {
                        Authorization: `Bearer ${accessToken}`,
                      },
                    }
                  );

                const imageId = imageResponse.data.image._id;

                return imageId;
            }

        } catch (error) {
            console.log(error);
        }
    };

    formData.append("image", file);

    //Fonction qui va créer l'annexe et l'ajouter à la collection annexe

    const handleSubmit = async (e) => {
        e.preventDefault();

        const imageId = await createImage();

        if (!imageId) {
            notify("Une erreur est survenue lors de la création de l'image", "error");
            return;
        }

        const postData = {
            image: imageId,
            desc: description
        };

        try {
            const response = await axios.post(`${serverUrl}/annexe/create`, postData, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });

            if (response.status === 200) {
                notify(`${response?.data?.message || ""}`, "success");
            }
        } catch (error) {
            setAccessToken("");
            sessionStorage.removeItem("accessToken");
            notify(`Une erreur est survenue ${error?.response?.data?.message || ""}`, "error");
        }
    };

    //Récupère les images de la collection image

    useEffect(() => {
        axios.get(`${serverUrl}/image`)
            .then((response) => {
                setImage(response.data.Images);
            })
            .catch((error) => {
                console.log(error);
            });
    }, [serverUrl]);

    return (
        <>
            <form onSubmit={handleSubmit} className="form-container">
                <div className='select'>
                    <div className='checkbox-align'>
                        <label htmlFor="image" className='checkbox-align__label'>Choisir une image déjà importé:</label>
                        <label htmlFor="checkbox" className='toggle-switch'>
                            <input type="checkbox" name="seeUploadedCheckbox" id="seeUploadedCheckbox" className='toggle-input' onClick={handleCheckboxChange} />
                            <label htmlFor="seeUploadedCheckbox" className='toggle-label'></label>
                        </label>
                    </div>
                    {showFileInput && (
                        <div className="container">
                            <div className="card">
                                <div
                                    className="drop_box"
                                    ref={dropArea}
                                    onDragOver={handleDragOver}
                                    onDragLeave={handleDragLeave}
                                    onDrop={handleDrop}
                                >
                                    <header ref={dragText}>
                                        <h4>Déposez votre fichier ici</h4>
                                    </header>
                                    {file && <p>Le fichier sélectionné est : {file.name}</p>}
                                    <input type="file" hidden accept=".png,.jpeg,.gif" id="image" onChange={handleFileChange} />
                                    <button className="btn" onClick={handleImageSelect}>Choisir une image</button>
                                </div>
                            </div>
                        </div>
                    )}
                    {seeUploaded ? (
                        <Select
                            isMulti
                            value={selectedImage.map((id) => ({
                                value: id,
                                label: image.find((image) => image._id === id).url
                            }))}
                            options={image.map((image) => ({
                                value: image._id,
                                label: (
                                    <div>
                                        <img src={image.url} alt={image._id} style={{ height: "50px" }} />
                                        <span>{image.url}</span>
                                    </div>
                                )
                            }))}
                            formatOptionLabel={(option) => option.label}
                            onChange={(selectedOptions) =>
                                setSelectedImage(selectedOptions.map((option) => option.value))
                            }
                        />
                    ) : null}
                </div>
                <div>
                    <label htmlFor="description">Description:</label>
                    <textarea id="description" value={description} onChange={(e) => setDescription(e.target.value)} />
                </div>
                <button type="submit">Créer</button>
            </form>
        </>
    );
}

export default AnnexeForm;
import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import PostForm from "./PostForm";
import Modal from "react-modal";
import API from '../API';
import LieuForm from "./LieuForm";
import EnigmeForm from "./EnigmeForm";
import AnnexeForm from "./AnnexeForm";
import ImageForm from "./ImageForm";
import PostEdit from "./PostEdit";
import LieuEdit from "./LieuEdit";
import EnigmeEdit from "./EnigmeEdit";
import AnnexeEdit from "./AnnexeEdit";
import 'react-toastify/dist/ReactToastify.css';
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

Modal.setAppElement("#root");

function CRUD(props) {
  const { notify, selectedPost, accessToken, setAccessToken} = props;
  const [posts, setPosts] = useState([]);
  const [annexes, setAnnexes] = useState([]);
  const [locations, setLocations] = useState([]);
  const [riddles, setRiddles] = useState([]);
  const [images, setImages] = useState([]);
  const [isOpenPost, setIsOpenPost] = useState(false);
  const [isOpenLocation, setIsOpenLocation] = useState(false);
  const [isOpenRiddle, setIsOpenRiddle] = useState(false);
  const [isOpenAnnexe, setIsOpenAnnexe] = useState(false);
  const [isOpenImage, setIsOpenImage] = useState(false);
  const [editPostModalOpen, setEditPostModalOpen] = useState(false);
  const [editingPost, setEditingPost] = useState(null);
  const [editingLieu, setEditingLieu] = useState(null);
  const [editLieuModalOpen, setEditLieuModalOpen] = useState(false);
  const [editingEnigme, setEditingEnigme] = useState(null);
  const [editEnigmeModalOpen, setEditEnigmeModalOpen] = useState(false);
  const [editingAnnexe, setEditingAnnexe] = useState(null);
  const [editAnnexeModalOpen, setEditAnnexeModalOpen] = useState(false);
  const { serverUrl } = useContext(API);

  //Gère les modals selon leur ouverture ou fermeture et stock les id ou code 
  //afin de les réutiliser dans les différents composants

  const customStyles = {
    content: {
      backgroundColor: '#232323',
      border: 'none',
      overflowY: 'auto',
      maxHeight: '95vh',
    },
  };
  
  const [currentLocations, setCurrentLocations] = useState([]);
  const [currentRiddles, setCurrentRiddles] = useState([]);
  const [currentAnnexes, setCurrentAnnexes] = useState([]);
  const [currentImages, setCurrentImages] = useState([]);
  
  const [postsPerPage, setPostsPerPage] = useState(5);
  const [locationsPerPage, setLocationsPerPage] = useState(5);
  const [riddlesPerPage, setRiddlesPerPage] = useState(5);
  const [annexesPerPage, setAnnexesPerPage] = useState(5);
  const [imagesPerPage, setImagesPerPage] = useState(5);
  
  const [totalLocationPages, setTotalLocationPages] = useState(1);
  const [totalRiddlePages, setTotalRiddlePages] = useState(1);
  const [totalAnnexePages, setTotalAnnexePages] = useState(1);
  const [totalImagePages, setTotalImagePages] = useState(1);
  
  const [currentPage, setCurrentPage] = useState(1);
  const [currentLocationPage, setCurrentLocationPage] = useState(1);
  const [currentRiddlePage, setCurrentRiddlePage] = useState(1);
  const [currentAnnexePage, setCurrentAnnexePage] = useState(1);
  const [currentImagePage, setCurrentImagePage] = useState(1);

  const openPostModal = () => setIsOpenPost(true);
  const closePostModal = () => setIsOpenPost(false);

  const openLocationModal = () => setIsOpenLocation(true);
  const closeLocationModal = () => setIsOpenLocation(false);

  const openRiddleModal = () => setIsOpenRiddle(true);
  const closeRiddleModal = () => setIsOpenRiddle(false);

  const openAnnexeModal = () => setIsOpenAnnexe(true);
  const closeAnnexeModal = () => setIsOpenAnnexe(false);

  const openImageModal = () => setIsOpenImage(true);
  const closeImageModal = () => setIsOpenImage(false);

  const openEditPostModal = (post) => {
    setEditingPost(post.code);
    setEditPostModalOpen(true);
  };

  const closeEditPostModal = () => {
    setEditingPost(null);
    setEditPostModalOpen(false);
  };

  const openEditLieuModal = (lieu) => {
    setEditingLieu(lieu._id);
    setEditLieuModalOpen(true);
  };

  const closeEditLieuModal = () => {
    setEditingLieu(null);
    setEditLieuModalOpen(false);
  };

  const openEditEnigmeModal = (riddle) => {
    setEditingEnigme(riddle._id);
    setEditEnigmeModalOpen(true);
  };

  const closeEditEnigmeModal = () => {
    setEditingEnigme(null);
    setEditEnigmeModalOpen(false);
  };

  const openEditAnnexeModal = (annexe) => {
    setEditingAnnexe(annexe._id);
    setEditAnnexeModalOpen(true);
  };

  const closeEditAnnexeModal = () => {
    setEditingAnnexe(null);
    setEditAnnexeModalOpen(false);
  };

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = posts.slice(indexOfFirstPost, indexOfLastPost);
  const totalPages = Math.ceil(posts.length / postsPerPage);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleLocationPageChange = (newPage) => {
    setCurrentLocationPage(newPage);
  };

  const handleRiddlePageChange = (newPage) => {
    setCurrentRiddlePage(newPage);
  };

  const handleAnnexePageChange = (newPage) => {
    setCurrentAnnexePage(newPage);
  };

  const handleImagePageChange = (newPage) => {
    setCurrentImagePage(newPage);
  };

  useEffect(() => {
    const indexOfLastLocation = currentLocationPage * locationsPerPage;
    const indexOfFirstLocation = indexOfLastLocation - locationsPerPage;
    setCurrentLocations(locations.slice(indexOfFirstLocation, indexOfLastLocation));
    setTotalLocationPages(Math.ceil(locations.length / locationsPerPage));
  }, [locations, currentLocationPage, locationsPerPage]);

  useEffect(() => {
    const indexOfLastRiddle = currentRiddlePage * riddlesPerPage;
    const indexOfFirstRiddle = indexOfLastRiddle - riddlesPerPage;
    setCurrentRiddles(riddles.slice(indexOfFirstRiddle, indexOfLastRiddle));
    setTotalRiddlePages(Math.ceil(riddles.length / riddlesPerPage));
  }, [riddles, currentRiddlePage, riddlesPerPage]);

  useEffect(() => {
    const indexOfLastAnnexe = currentAnnexePage * annexesPerPage;
    const indexOfFirstAnnexe = indexOfLastAnnexe - annexesPerPage;
    setCurrentAnnexes(annexes.slice(indexOfFirstAnnexe, indexOfLastAnnexe));
    setTotalAnnexePages(Math.ceil(annexes.length / annexesPerPage));
  }, [annexes, currentAnnexePage, annexesPerPage]);

  useEffect(() => {
    const indexOfLastImage = currentImagePage * imagesPerPage;
    const indexOfFirstImage = indexOfLastImage - imagesPerPage;
    setCurrentImages(images.slice(indexOfFirstImage, indexOfLastImage));
    setTotalImagePages(Math.ceil(images.length / imagesPerPage));
  }, [images, currentImagePage, imagesPerPage]);

  // Tableau des indices des pages à afficher
  const visiblePages = [];

  // Vérifier si la page courante est proche des extrémités
  if (currentPage <= 2) {
    // Afficher les 3 premières pages
    visiblePages.push(1, 2, 3);
  } else if (currentPage >= totalPages - 1) {
    // Afficher les 3 dernières pages
    visiblePages.push(totalPages - 2, totalPages - 1, totalPages);
  } else {
    // Afficher la page courante et les 2 pages précédentes et suivantes
    visiblePages.push(currentPage - 1, currentPage, currentPage + 1);
  }

  const visibleLocationPages = [];

  if (currentLocationPage <= 2) {
    visibleLocationPages.push(1, 2, 3);
  } else if (currentLocationPage >= totalLocationPages - 1) {
    visibleLocationPages.push(totalLocationPages - 2, totalLocationPages - 1, totalLocationPages);
  } else {
    visibleLocationPages.push(currentLocationPage - 1, currentLocationPage, currentLocationPage + 1);
  }

  const visibleRiddlePages = [];

  if (currentRiddlePage <= 2) {
    visibleRiddlePages.push(1, 2, 3);
  } else if (currentRiddlePage >= totalRiddlePages - 1) {
    visibleRiddlePages.push(totalRiddlePages - 2, totalRiddlePages - 1, totalRiddlePages);
  } else {
    visibleRiddlePages.push(currentRiddlePage - 1, currentRiddlePage, currentRiddlePage + 1);
  }

  const visibleAnnexePages = [];

  if (currentAnnexePage <= 2) {
    visibleAnnexePages.push(1, 2, 3);
  } else if (currentAnnexePage >= totalAnnexePages - 1) {
    visibleAnnexePages.push(totalAnnexePages - 2, totalAnnexePages - 1, totalAnnexePages);
  } else {
    visibleAnnexePages.push(currentAnnexePage - 1, currentAnnexePage, currentAnnexePage + 1);
  }

  const visibleImagePages = [];

  if (currentImagePage <= 2) {
    visibleImagePages.push(1, 2, 3);
  } else if (currentImagePage >= totalImagePages - 1) {
    visibleImagePages.push(totalImagePages - 2, totalImagePages - 1, totalImagePages);
  } else {
    visibleImagePages.push(currentImagePage - 1, currentImagePage, currentImagePage + 1);
  }


  //Fonctions de suppression des données

  const deletePost = async (id) => {
    try {
      const response = await axios.delete(`${serverUrl}/post/${id}`, {
        headers: {
          "Authorization": `Bearer ${accessToken}`,
        },
      });
  
      if (response.status === 200) {
        setPosts(posts.filter((post) => post._id !== id));
        notify("Post supprimé", "success");
      }
    } catch (error) {
      setAccessToken("");
      sessionStorage.removeItem("accessToken");
      console.error(error);
    }
  };
  
  const deleteLocation = async (id) => {
    try {
      const response = await axios.delete(`${serverUrl}/lieu/${id}`, {
        headers: {
          "Authorization": `Bearer ${accessToken}`,
        },
      });
  
      if (response.status === 200) {
        setLocations(locations.filter((location) => location._id !== id));
        notify("Lieu supprimé", "success");
      }
    } catch (error) {
      setAccessToken("");
      sessionStorage.removeItem("accessToken");
      console.error(error);
    }
  };
  
  const deleteRiddle = async (id) => {
    try {
      const response = await axios.delete(`${serverUrl}/enigme/${id}`, {
        headers: {
          "Authorization": `Bearer ${accessToken}`,
        },
      });
  
      if (response.status === 200) {
        setRiddles(riddles.filter((riddle) => riddle._id !== id));
        notify("Enigme supprimée", "success");
      }
    } catch (error) {
      setAccessToken("");
      sessionStorage.removeItem("accessToken");
      console.error(error);
    }
  };
  
  const deleteAnnexe = async (id) => {
    try {
      const response = await axios.delete(`${serverUrl}/annexe/${id}`, {
        headers: {
          "Authorization": `Bearer ${accessToken}`,
        },
      });
  
      if (response.status === 200) {
        setAnnexes(annexes.filter((annexe) => annexe._id !== id));
        notify("Annexe supprimée", "success");
      }
    } catch (error) {
      setAccessToken("");
      sessionStorage.removeItem("accessToken");
      console.error(error);
    }
  };
  
  const deleteImage = async (id) => {
    try {
      const response = await axios.delete(`${serverUrl}/image/${id}`, {
        headers: {
          "Authorization": `Bearer ${accessToken}`,
        },
      });
  
      if (response.status === 200) {
        setImages(images.filter((image) => image._id !== id));
        notify("Image supprimée", "success");
      }
    } catch (error) {
      setAccessToken("");
      sessionStorage.removeItem("accessToken");
      console.error(error);
    }
  };
  
  //Fonctions qui récupère les données de la base de données

  useEffect(() => {
    axios.get(`${serverUrl}/post`)
      .then(response => {
        setPosts(response.data.posts);
      })
      .catch(error => {
        console.error(error);
      });

    axios.get(`${serverUrl}/lieu`)
      .then(response => {
        setLocations(response.data.Lieux);
      })
      .catch(error => {
        console.error(error);
      });

    axios.get(`${serverUrl}/enigme`)
      .then(response => {
        setRiddles(response.data.Enigmes);
      })
      .catch(error => {
        console.error(error);
      });

    axios.get(`${serverUrl}/image`)
      .then(response => {
        setImages(response.data.Images);
      })
      .catch(error => {
        console.error(error);
      });

    axios.get(`${serverUrl}/annexe`)
      .then(response => {
        setAnnexes(response.data.Annexes);
      })
      .catch(error => {
        console.error(error)
      });

  }, [serverUrl]);

  return (
    <>
      <div className="crud">
        <div className="crud__top">
          <div className="crudContainer">
            <Modal className="modalcrud" isOpen={isOpenPost} onRequestClose={closePostModal} style={customStyles}>
              <button className='modal__close-button' onClick={closePostModal}>X</button>
              <h2 className="crudContainer__title">Créer un post</h2>
              <PostForm selectedPost={selectedPost} notify={notify} accessToken={accessToken} setAccessToken={setAccessToken} />
            </Modal>

            <Modal className="modalcrud" isOpen={editPostModalOpen} onRequestClose={closeEditPostModal} style={customStyles}>
              <button className='modal__close-button' onClick={closeEditPostModal}>X</button>
              <PostEdit postCode={editingPost} notify={notify} accessToken={accessToken} setAccessToken={setAccessToken} />
            </Modal>
            <div className="crudContainer__titles">
              <label className="crudContainer__titles__title" htmlFor="Post">Posts:</label>
              <button className="crudContainer__titles__button" onClick={openPostModal}>
                <FontAwesomeIcon icon={faPlus} />
              </button>
            </div>
            {currentPosts.map(post => (
              <div key={post._id}>
                <div className="crudContainer__main">
                  <div className="crudContainer__main__title">{post.title}</div>
                  <div className="crudContainer__main__sub">
                    <button className="crudContainer__main__sub__edit" onClick={() => openEditPostModal(post)}>
                      <FontAwesomeIcon icon={faPenToSquare} />
                    </button>
                    <button className="crudContainer__main__sub__delete" onClick={() => {
                      if (window.confirm("Êtes-vous sûr de vouloir supprimer ce post ?")) {
                        deletePost(post._id);
                      }
                    }}>
                      <FontAwesomeIcon icon={faTrash} />
                    </button>
                  </div>
                </div>
              </div>
            ))}
            <div className="crudContainer__pagination">
              <button className="crudContainer__pagination__button" onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>
                Précédent
              </button>
              {visiblePages.map((page) => (
                <button
                  key={page}
                  onClick={() => handlePageChange(page)}
                  className={`crudContainer__pagination__number ${currentPage === page ? 'active' : ''}`}>
                  {page}
                </button>
              ))}
              <button className="crudContainer__pagination__button" onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages}>
                Suivant
              </button>
            </div>

          </div>
          <div className="crudContainer">
            <Modal className="modalcrud" isOpen={isOpenLocation} onRequestClose={closeLocationModal} style={customStyles}>
              <button className='modal__close-button' onClick={closeLocationModal}>X</button>
              <h2 className="crudContainer__title">Créer un lieu</h2>
              <LieuForm notify={notify} accessToken={accessToken} setAccessToken={setAccessToken} />
            </Modal>

            <Modal className="modalcrud" isOpen={editLieuModalOpen} onRequestClose={closeEditLieuModal} style={customStyles}>
              <button className='modal__close-button' onClick={closeEditLieuModal}>X</button>
              <LieuEdit lieuId={editingLieu} notify={notify} accessToken={accessToken} setAccessToken={setAccessToken} />
            </Modal>
            <div className="crudContainer__titles">
              <label className="crudContainer__titles__title" htmlFor="Lieu">Lieux:</label>
              <button className="crudContainer__titles__button" onClick={openLocationModal}>
                <FontAwesomeIcon icon={faPlus} />
              </button>
            </div>
            {currentLocations.map(location => (
              <div key={location._id}>
                <div className="crudContainer__main">
                  <div className="crudContainer__main__title">{location.title}</div>
                  <div className="crudContainer__main__sub">
                    <button className="crudContainer__main__sub__edit" onClick={() => openEditLieuModal(location)}>
                      <FontAwesomeIcon icon={faPenToSquare} />
                    </button>
                    <button className="crudContainer__main__sub__delete" onClick={() => {
                      if (window.confirm("Êtes-vous sûr de vouloir supprimer ce lieu ?")) {
                        deleteLocation(location._id);
                      }
                    }}>
                      <FontAwesomeIcon icon={faTrash} />
                    </button>
                  </div>
                </div>
              </div>
            ))}
            <div className="crudContainer__pagination">
              <button className="crudContainer__pagination__button" onClick={() => handleLocationPageChange(currentLocationPage - 1)} disabled={currentLocationPage === 1}>
                Précédent
              </button>
              {visibleLocationPages.map((page) => (
                <button
                  key={page}
                  onClick={() => handleLocationPageChange(page)}
                  className={`crudContainer__pagination__number ${currentLocationPage === page ? 'active' : ''}`}>
                  {page}
                </button>
              ))}
              <button className="crudContainer__pagination__button" onClick={() => handleLocationPageChange(currentLocationPage + 1)} disabled={currentLocationPage === totalLocationPages}>
                Suivant
              </button>
            </div>
          </div>
        </div>
        <div className="crud__mid">
          <div className="crudContainer">
            <Modal className="modalcrud" isOpen={isOpenRiddle} onRequestClose={closeRiddleModal} style={customStyles}>
              <button className='modal__close-button' onClick={closeRiddleModal}>X</button>
              <h2 className="crudContainer__title">Créer une énigme</h2>
              <EnigmeForm notify={notify} accessToken={accessToken} setAccessToken={setAccessToken} />
            </Modal>

            <Modal className="modalcrud" isOpen={editEnigmeModalOpen} onRequestClose={closeEditEnigmeModal} style={customStyles}>
              <button className='modal__close-button' onClick={closeEditEnigmeModal}>X</button>
              <EnigmeEdit EnigmeId={editingEnigme} notify={notify} accessToken={accessToken} setAccessToken={setAccessToken}/>
            </Modal>
            <div className="crudContainer__titles">
              <label className="crudContainer__titles__title" htmlFor="Enigme">Enigmes:</label>
              <button className="crudContainer__titles__button" onClick={openRiddleModal}>
                <FontAwesomeIcon icon={faPlus} />
              </button>
            </div>
            {currentRiddles.map(riddle => (
              <div key={riddle._id}>
                <div className="crudContainer__main">
                  <div className="crudContainer__main__title">{riddle.title}</div>
                  <div className="crudContainer__main__sub">
                    <button className="crudContainer__main__sub__edit" onClick={() => openEditEnigmeModal(riddle)}>
                      <FontAwesomeIcon icon={faPenToSquare} />
                    </button>
                    <button className="crudContainer__main__sub__delete" onClick={() => {
                      if (window.confirm("Êtes-vous sûr de vouloir supprimer cette enigme ?")) {
                        deleteRiddle(riddle._id);
                      }
                    }}>
                      <FontAwesomeIcon icon={faTrash} />
                    </button>
                  </div>
                </div>
              </div>
            ))}
            <div className="crudContainer__pagination">
              <button className="crudContainer__pagination__button" onClick={() => handleRiddlePageChange(currentRiddlePage - 1)} disabled={currentRiddlePage === 1}>
                Précédent
              </button>
              {visibleRiddlePages.map((page) => (
                <button
                  key={page}
                  onClick={() => handleRiddlePageChange(page)}
                  className={`crudContainer__pagination__number ${currentRiddlePage === page ? 'active' : ''}`}>
                  {page}
                </button>
              ))}
              <button className="crudContainer__pagination__button" onClick={() => handleRiddlePageChange(currentRiddlePage + 1)} disabled={currentRiddlePage === totalRiddlePages}>
                Suivant
              </button>
            </div>

          </div>
          <div className="crudContainer">
            <Modal className="modalcrud" isOpen={isOpenAnnexe} onRequestClose={closeAnnexeModal} style={customStyles}>
              <button className='modal__close-button' onClick={closeAnnexeModal}>X</button>
              <h2 className="crudContainer__title">Créer une annexe</h2>
              <AnnexeForm notify={notify} accessToken={accessToken} setAccessToken={setAccessToken} />
            </Modal>

            <Modal className="modalcrud" isOpen={editAnnexeModalOpen} onRequestClose={closeEditAnnexeModal} style={customStyles}>
              <button className='modal__close-button' onClick={closeEditAnnexeModal}>X</button>
              <AnnexeEdit AnnexeId={editingAnnexe} notify={notify} accessToken={accessToken} setAccessToken={setAccessToken} />
            </Modal>
            <div className="crudContainer__titles">
              <label className="crudContainer__titles__title" htmlFor="Annexe">Annexes:</label>
              <button className="crudContainer__titles__button" onClick={openAnnexeModal}>
                <FontAwesomeIcon icon={faPlus} />
              </button>
            </div>
            {currentAnnexes.map(annexe => (
              <div key={annexe._id}>
                <div className="crudContainer__main">
                  <div className="crudContainer__main__title">{annexe.desc}</div>
                  <div className="crudContainer__main__sub">
                    <button className="crudContainer__main__sub__edit" onClick={() => openEditAnnexeModal(annexe)}>
                      <FontAwesomeIcon icon={faPenToSquare} />
                    </button>
                    <button className="crudContainer__main__sub__delete" onClick={() => {
                      if (window.confirm("Êtes-vous sûr de vouloir supprimer cette annexe ?")) {
                        deleteAnnexe(annexe._id);
                      }
                    }}>
                      <FontAwesomeIcon icon={faTrash} />
                    </button>
                  </div>
                </div>
              </div>
            ))}
            <div className="crudContainer__pagination">
              <button className="crudContainer__pagination__button" onClick={() => handleAnnexePageChange(currentAnnexePage - 1)} disabled={currentAnnexePage === 1}>
                Précédent
              </button>
              {visibleAnnexePages.map((page) => (
                <button
                  key={page}
                  onClick={() => handleAnnexePageChange(page)}
                  className={`crudContainer__pagination__number ${currentAnnexePage === page ? 'active' : ''}`}>
                  {page}
                </button>
              ))}
              <button className="crudContainer__pagination__button" onClick={() => handleAnnexePageChange(currentAnnexePage + 1)} disabled={currentAnnexePage === totalAnnexePages}>
                Suivant
              </button>
            </div>
          </div>
        </div>
        <div className="crud__bot">
          <div className="crudContainer">
            <Modal className="modalcrud" isOpen={isOpenImage} onRequestClose={closeImageModal} style={customStyles}>
              <button className='modal__close-button' onClick={closeImageModal}>X</button>
              <h2 className="crudContainer__title">Créer une image</h2>
              <ImageForm notify={notify} accessToken={accessToken} setAccessToken={setAccessToken}/>
            </Modal>
            <div className="crudContainer__titles">
              <label className="crudContainer__titles__title" htmlFor="Image">Images:</label>
              <button className="crudContainer__titles__button" onClick={openImageModal}>
                <FontAwesomeIcon icon={faPlus} />
              </button>
            </div>
            <div className="crudContainer__container">
              {currentImages.map(image => (
                <div className='crudContainer__container__images' key={image._id}>
                  <div className="crudContainer__container__images">
                    <img className="crudContainer__container__images__image" src={image.url} alt="" />
                    <button className="crudContainer__container__images__delete" onClick={() => {
                      if (window.confirm("Êtes-vous sûr de vouloir supprimer cette image ?")) {
                        deleteImage(image._id);
                      }
                    }}>
                      <FontAwesomeIcon icon={faTrash} />
                    </button>
                  </div>
                </div>
              ))}
            </div>
            <div className="crudContainer__pagination">
              <button className="crudContainer__pagination__button" onClick={() => handleImagePageChange(currentImagePage - 1)} disabled={currentImagePage === 1}>
                Précédent
              </button>
              {visibleImagePages.map((page) => (
                <button
                  key={page}
                  onClick={() => handleImagePageChange(page)}
                  className={`crudContainer__pagination__number ${currentImagePage === page ? 'active' : ''}`}>
                  {page}
                </button>
              ))}
              <button className="crudContainer__pagination__button" onClick={() => handleImagePageChange(currentImagePage + 1)} disabled={currentImagePage === totalImagePages}>
                Suivant
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CRUD;
import React, { useContext, useState } from 'react'
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import API from '../API';
import Navbar from '../Components/Navbar';


export default function LoginPage(props) {
  const { setIsLogged, notify, setAccessToken } = props;
  const { serverUrl } = useContext(API);

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    const userData = {
      username: username,
      password: password,
  }

    // if (setIsLogged=== true) {
    //   notify("Déjà connecté");
    //   navigate("/dashboard");
    //   return null;
    // }

    if (Object.values(userData).includes("")) {
      notify("Veuillez remplir tous les champs", "warning");
      return;
    }

    axios.post(`${serverUrl}/user/signin`, userData)
      .then((response) => {
        const accessToken = response.data.accessToken;
        setAccessToken(accessToken);
        sessionStorage.setItem("accessToken", accessToken);
        setIsLogged(true);
        notify(response.data.message, "success");
        navigate("/dashboard");
      })
      .catch((error) => {
        notify(`Une erreur est survenue ${error?.response?.data?.message || ""}`, "error");
      });
  }

  return (
    <>
      <Helmet>
        <title>Connexion</title>
      </Helmet>
      <Navbar />
      <div className='login__container'>
        <h1 className='login__container__title'>Connexion</h1>
        <form className='login__container__form' type='post' onSubmit={(e) => handleSubmit(e)}>
          <div className='login__container__form__infos'>
            <label htmlFor="username">Email/Pseudo</label>
            <input id="username" value={username} onChange={(e) => setUsername(e.currentTarget.value)} type="text" placeholder="Email.. / Pseudo.." />
          </div>
          <div className='login__container__form__infos'>
            <label htmlFor="password">Mot de passe</label>
            <input id="password" value={password} onChange={(e) => setPassword(e.currentTarget.value)} type="password" placeholder="*****" />
          </div>
          <button className='login__container__form__button' type="submit">
            Connexion
          </button>
        </form>
      </div>
    </>
  )
}
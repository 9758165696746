import React, { useState, useEffect, useContext, useRef } from 'react';
import axios from 'axios';
import FormData from 'form-data';
import Select from 'react-select';
import 'react-toastify/dist/ReactToastify.css';
import API from '../API';

function AnnexeEdit({ AnnexeId, ...props }) {
    const { notify, accessToken, setAccessToken } = props;
    const { serverUrl } = useContext(API);
    const [description, setDescription] = useState('');
    const [file, setFile] = useState(null);
    const [image, setImage] = useState(null);
    const [selectedImage, setSelectedImage] = useState([]);
    const [seeUploaded, setSeeUploaded] = useState(selectedImage.length > 0)
    const formData = new FormData();
    const [selectedAnnexe, setSelectedAnnexe] = useState();
    const [dropArea, setDropArea] = useState(null);

    useEffect(() => {
        const dropArea = document.querySelector(".drop_box");
        if (dropArea) {
            const dragText = dropArea.querySelector("header");

            const handleDragOver = (e) => {
                e.preventDefault();
                dropArea.classList.add("dragging");
                dragText.textContent = "Relâchez le fichier";
            };

            const handleDragLeave = () => {
                dropArea.classList.remove("dragging");
                dragText.textContent = "Déposez le fichier ici";
            };

            const handleDrop = (e) => {
                e.preventDefault();
                dropArea.classList.remove("dragging");
                dragText.textContent = "Déposez le fichier ici";
                const file = e.dataTransfer.files[0];
                setFile(file);
            };

            dropArea.addEventListener("dragover", handleDragOver);
            dropArea.addEventListener("dragleave", handleDragLeave);
            dropArea.addEventListener("drop", handleDrop);

            setDropArea(dropArea);
        }
    }, []);

    const handleDragOver = (e) => {
        e.preventDefault();
        dropArea.classList.add("dragging");
        dragText.textContent = "Relâchez le fichier";
    };

    const handleDragLeave = () => {
        dropArea.classList.remove("dragging");
        dragText.textContent = "Déposez le fichier ici";
    };

    const handleDrop = (e) => {
        e.preventDefault();
        dropArea.classList.remove("dragging");
        dragText.textContent = "Déposez le fichier ici";
        const file = e.dataTransfer.files[0];
        setFile(file);
    };

    let dragText = useRef(null);


    //Met à jour le fichier quand il est seléctionné

    const handleFileChange = (event) => {
        setFile(event.target.files[0]);
    };

    const handleImageSelect = (event) => {
        event.preventDefault();
        document.getElementById('image').click();
    };

    //Affiche le select si la checkbox est coché

    const handleCheckboxChange = () => {
        setSeeUploaded(prevSeeUploaded => !prevSeeUploaded);
    };

    //Fonction qui va être utilisé si une image doit être upload,
    //elle va utiliser l'API d'imgur pour récuperer un lien et le stocké dans la BDD

    const createImage = async () => {
        try {
            let imageUrl;

            //vérifie si la checkbox est coché, si oui il va récupérer l'id de l'image
            //pour sortir de la fonction

            if (seeUploaded === true) {
                imageUrl = selectedImage.map((id) => image.find((img) => img._id === id)._id
                );
                return imageUrl;

                //si la checkbox n'est pas coché il va prendre le fichier et l'upload sur imgur

            } else {
                const response = await axios({
                    method: "Annexe",
                    url: "https://api.imgur.com/3/image",
                    data: formData,
                    headers: {
                        Authorization: "Client-ID 41e0d680636e63d",
                        "Content-Type": "multipart/form-data",
                    },
                });

                //récupère l'URL de l'image uploadé et poste l'url de l'image dans la collection image

                imageUrl = response.data.data.link;

                const imageResponse = await axios.post(
                    `${serverUrl}/image/create`,
                    {
                      url: imageUrl,
                    },
                    {
                      headers: {
                        Authorization: `Bearer ${accessToken}`,
                      },
                    }
                  );

                const imageId = imageResponse.data.image._id;

                return imageId;
            }

        } catch (error) {
            console.log(error);
        }
    };

    formData.append("image", file);

    //récupère toutes les images pour le select afin de pouvoir les choisir

    useEffect(() => {
        axios.get(`${serverUrl}/image`)
            .then(response => {
                setImage(response.data.Images);
                setSelectedImage(selectedAnnexe ? [selectedAnnexe.image] : []);
            })
            .catch(error => {
                console.error(error);
            });
    }, [serverUrl, selectedAnnexe]);

    //récupère les informations de l'annexe a modifier 

    useEffect(() => {
        const handleAnnexeSelect = async (AnnexeId) => {
            try {
                const response = await axios.get(`${serverUrl}/annexe/${AnnexeId}`);
                if (response.status === 200) {
                    setSelectedAnnexe(response.data.annexe);
                }
            } catch (error) {
                notify(`Une erreur est survenue ${error?.response?.data?.message || ""}`, "error");
            }
        };
        if (AnnexeId) {
            handleAnnexeSelect(AnnexeId);
        }
    }, [AnnexeId, serverUrl, notify]);

    useEffect(() => {
        if (selectedAnnexe) {
            setDescription(selectedAnnexe.desc);
            setSelectedImage([selectedAnnexe.image]);
        }
    }, [selectedAnnexe]);

    //va mettre à jour la BDD avec les nouvelles informations

    const handleUpdate = async (e) => {
        e.preventDefault();

        const imageId = await createImage();

        if (!imageId) {
            notify('Erreur lors de la création de l\'image');
            return;
        }

        const AnnexeData = {
            image: imageId,
            desc: description,
        };

        try {
            if (!selectedAnnexe) {
                notify("Aucun Annexe sélectionné", "error");
                return;
            }

            const response = await axios.patch(`${serverUrl}/annexe/${selectedAnnexe._id}`, AnnexeData, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });

            if (response.status === 200) {
                notify(`${response?.data?.message || ""}`, "success");
            }

        } catch (error) {
            setAccessToken("");
            sessionStorage.removeItem("accessToken");
            notify(`Une erreur est survenue ${error?.response?.data?.message || ""}`, "error");
            console.log(error);
        }
    };

    return (
        <>
            <form onSubmit={handleUpdate} className="form-container">
                <div className='select'>
                </div>
                <Select
                    isMulti
                    value={(selectedImage ?? []).map((id) => ({
                        value: id,
                        label: (image ?? []).find((image) => image._id === id)?.url ?? ''
                    }))}
                    options={(image ?? []).map((image) => ({
                        value: image._id,
                        label: (
                            <div>
                                <img src={image.url} alt={image._id} style={{ height: "50px" }} />
                                <span>{image.url}</span>
                            </div>
                        )
                    }))}
                    formatOptionLabel={(option) => option.label}
                    onChange={(selectedOptions) =>
                        setSelectedImage(selectedOptions.map((option) => option.value))
                    }
                />

                <div>
                    <label htmlFor="description">Description:</label>
                    <textarea id="description" value={description} onChange={(e) => setDescription(e.target.value)} />
                </div>
                <button type="submit">Modifier le Annexe</button>
            </form>
        </>
    );
}

export default AnnexeEdit;


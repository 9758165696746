import React from 'react'
import Index from '../Components/Index'

export default function Indexpage() {
  return (
    <>
    <Index />
    </>
    )
}

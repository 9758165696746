import { useState, useEffect, useContext } from 'react';
import ImageZoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';
import axios from 'axios';
import API from '../API';
import { Helmet } from 'react-helmet';

export default function ShowAnnexe() {
  const [annexe, setAnnexe] = useState([]);
  const { serverUrl } = useContext(API);
  const [images, setImages] = useState([]);
  const [annexeImages, setAnnexeImages] = useState([]);

  useEffect(() => {
    axios
      .get(`${serverUrl}/image/`)
      .then((response) => setImages(response.data.Images))
      .catch((error) => console.error(error));
    axios
      .get(`${serverUrl}/annexe/`)
      .then((response) => setAnnexe(response.data.Annexes))
      .catch((error) => console.error(error));
  }, [serverUrl]);

  useEffect(() => {
    if (annexe.length > 0 && images.length > 0) {
      const filteredImages = images.filter((image) =>
        annexe.some((a) => a.image && a.image === image._id.toString())
      );
      setAnnexeImages(filteredImages);
    }
  }, [annexe, images]);

  return (
    <>
    <Helmet>
      <title>Annexes</title>
      </Helmet>
    <div className='container'>
      {annexe.map((annexe) => {
        const annexeImage = annexeImages.find(
          (image) => image._id.toString() === annexe.image
        );
        return (
          <div className='container__annexe' key={annexe.image}>
            {annexeImage && (
              <ImageZoom>
                <img className='container__annexe__image' src={annexeImage.url} alt="" />
              </ImageZoom>
            )}
          </div>
        );
      })}
    </div>
    </>
  );
}

import axios from 'axios';
import React, { useEffect, useState, useContext } from 'react';
import API from '../API';
import Loading from './Loading';
import { Helmet } from 'react-helmet';

export default function Index(props) {
    const { serverUrl } = useContext(API);
    const [backendData, setBackendData] = useState({});

    useEffect(() => {
        axios
            .get(`${serverUrl}/post`)
            .then(response => {
                const filteredPosts = response.data.posts.filter(post => post.code !== 'R34'); // Filtrer les posts avec un code différent de "R34"
                const sortedPosts = filteredPosts.sort((a, b) => a.title.localeCompare(b.title));
                setBackendData({ ...response.data, posts: sortedPosts });
                console.log(response.data.posts)
            })
            .catch(error => console.error(error));
    }, [serverUrl]);

    return (
        <>
            <Helmet>
                <title>Index</title>
            </Helmet>
            <div className='index__header'>
                <h1 className='index__header__code'>Code</h1>
                <h1 className='index__header__lieu'>Lieu</h1>
            </div>
            <div className='index__top'>
                {!Array.isArray(backendData.posts) || backendData.posts.length === 0 ? (
                    <Loading />
                ) : (
                    backendData.posts.map((post) => (
                        <div className='index__container' key={post.code}>
                            <hr className='index__container__separation' />
                            <div className='index__container__subcontainer'>
                                <p className='index__container__subcontainer__code'>{post.code}</p>
                                <p className='index__container__subcontainer__title'>{post.title}</p>
                            </div>
                        </div>
                    ))
                )}
            </div>
        </>
    );
}

import React from "react";
import Helmet from "react-helmet";
import Navbardashboard from "../Components/NavbarDashboard";
import Navbar from "../Components/Navbar";
import CRUD from "../Components/CRUD";

export default function DashboardPage(props) {
    const { accessToken, isLogged, setIsLogged, setAccessToken, notify } = props;

    return (
        <>
            <Helmet>
                <title>Dashboard</title>
            </Helmet>
            <Navbar />
            <div className='dashboard__container'>
                <Navbardashboard notify={notify} accessToken={accessToken} setAccessToken={setAccessToken} IsLogged={isLogged} setIsLogged={setIsLogged} />
                <CRUD notify={notify} accessToken={accessToken} setAccessToken={setAccessToken} IsLogged={isLogged} setIsLogged={setIsLogged} />
            </div>
        </>
    );
}
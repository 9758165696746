import { useState, useEffect, React, useContext } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import API from '../API';
import Loading from './Loading';

export default function LieuDetails() {
    const [lieu, setLieu] = useState({});
    const {serverUrl} = useContext(API);

    const { title } = useParams();
  
    useEffect(() => {
      axios
        .get(`${serverUrl}/lieu/${title}`)
        .then(response => setLieu(response.data.lieu))
        .catch(error => console.error(error));
    }, [title, serverUrl]);
  
    if (!lieu) {
      return <Loading />;
    }
  return (
    <div>
    <h1>{lieu.title}</h1>
    <p>{lieu.desc}</p>
    <p>{}</p>
  </div>
  )
}

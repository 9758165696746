import React, { useState, useContext, useRef, useEffect } from 'react';
import axios from 'axios';
import FormData from 'form-data';
import 'react-toastify/dist/ReactToastify.css';
import API from '../API';

function ImageForm(props) {
  const { serverUrl } = useContext(API);
  const { notify, accessToken, setAccessToken } = props;
  const [file, setFile] = useState(null);
  const formData = new FormData();
  const [dropArea, setDropArea] = useState(null);

  useEffect(() => {
    const dropArea = document.querySelector(".drop_box");
    if (dropArea) {
      const dragText = dropArea.querySelector("header");

      const handleDragOver = (e) => {
        e.preventDefault();
        dropArea.classList.add("dragging");
        dragText.textContent = "Relâchez le fichier";
      };

      const handleDragLeave = () => {
        dropArea.classList.remove("dragging");
        dragText.textContent = "Déposez le fichier ici";
      };

      const handleDrop = (e) => {
        e.preventDefault();
        dropArea.classList.remove("dragging");
        dragText.textContent = "Déposez le fichier ici";
        const file = e.dataTransfer.files[0];
        setFile(file);
      };

      dropArea.addEventListener("dragover", handleDragOver);
      dropArea.addEventListener("dragleave", handleDragLeave);
      dropArea.addEventListener("drop", handleDrop);

      setDropArea(dropArea);
    }
  }, []);

  let dragText = useRef(null);

  const handleDragOver = (e) => {
    e.preventDefault();
    dropArea.classList.add("dragging");
    dragText.textContent = "Relâchez le fichier";
  };

  const handleDragLeave = () => {
    dropArea.classList.remove("dragging");
    dragText.textContent = "Déposez le fichier ici";
  };

  const handleDrop = (e) => {
    e.preventDefault();
    dropArea.classList.remove("dragging");
    dragText.textContent = "Déposez le fichier ici";
    const file = e.dataTransfer.files[0];
    setFile(file);
  };



  //Met à jour le fichier quand il est séléctionné

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleImageSelect = (event) => {
    event.preventDefault();
    document.getElementById('image').click();
  };

  //Fonction qui va être utilisé si une image doit être upload,
  //elle va utiliser l'API d'imgur pour récuperer un lien et le stocké dans la BDD

  const createImage = async () => {
    try {
      let imageUrl;
      const response = await axios({
        method: "post",
        url: "https://api.imgur.com/3/image",
        data: formData,
        headers: {
          Authorization: `Client-ID 41e0d680636e63d`,
          "Content-Type": "multipart/form-data",
        },
      });
  
      imageUrl = response.data.data.link;
  
      const imageResponse = await axios.post(
        `${serverUrl}/image/create`,
        {
          url: imageUrl,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
  
      const imageId = imageResponse.data.image._id;
  
      return imageId;
    } catch (error) {
      console.log(error);
    }
  };
  
  
  formData.append("image", file);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const imageId = await createImage();

    if (!imageId) {
      notify('Erreur lors de la création de l\'image');
      return;
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit} className="form-container">
        <div className="container">
          <div className="card">
            <div
              className="drop_box"
              ref={dropArea}
              onDragOver={handleDragOver}
              onDragLeave={handleDragLeave}
              onDrop={handleDrop}
            >
              <header ref={dragText}>
                <h4>Déposez votre fichier ici</h4>
              </header>
              {file && <p>Le fichier sélectionné est : {file.name}</p>}
              <input type="file" hidden accept=".png,.jpeg,.gif" id="image" onChange={handleFileChange} />
              <button className="btn" onClick={handleImageSelect}>Choisir une image</button>
            </div>
          </div>
        </div>
        <button type="submit">Créer</button>
      </form>
    </>
  );
}


export default ImageForm;

import React, { useState, useEffect, useContext, useRef } from 'react';
import axios from "axios";
import FormData from 'form-data';
import Select from 'react-select';
import API from '../API';

function EnigmeForm(props) {
  const { serverUrl } = useContext(API);
  const { notify, accessToken, setAccessToken } = props;
  const [title, setTitle] = useState('');
  const [file, setFile] = useState(null);
  const [image, setImage] = useState([]);
  const [seeUploaded, setSeeUploaded] = useState(false);
  const formData = new FormData();
  const [selectedImage, setSelectedImage] = useState([]);
  const [showFileInput, setShowFileInput] = useState(true);
  const [dropArea, setDropArea] = useState(null);
  const [imageId, setImageId] = useState(null);
  const [description, setDescription] = useState('');
  const [answer, setAnswer] = useState('');
  const [text, setText] = useState('');
  const [indice, setIndice] = useState('');
  const [imageEnigmeId, setImageEnigmeId] = useState({});

  useEffect(() => {
    const dropArea = document.querySelector(".drop_box");
    if (dropArea) {
      const dragText = dropArea.querySelector("header");

      const handleDragOver = (e) => {
        e.preventDefault();
        dropArea.classList.add("dragging");
        dragText.textContent = "Relâchez le fichier";
      };

      const handleDragLeave = () => {
        dropArea.classList.remove("dragging");
        dragText.textContent = "Déposez le fichier ici";
      };

      const handleDrop = (e) => {
        e.preventDefault();
        dropArea.classList.remove("dragging");
        dragText.textContent = "Déposez le fichier ici";
        const file = e.dataTransfer.files[0];
        setFile(file);
      };

      dropArea.addEventListener("dragover", handleDragOver);
      dropArea.addEventListener("dragleave", handleDragLeave);
      dropArea.addEventListener("drop", handleDrop);

      setDropArea(dropArea);
    }
  }, []);

  const handleDragOver = (e) => {
    e.preventDefault();
    dropArea.classList.add("dragging");
    dragText.textContent = "Relâchez le fichier";
  };

  const handleDragLeave = () => {
    dropArea.classList.remove("dragging");
    dragText.textContent = "Déposez le fichier ici";
  };

  const handleDrop = (e) => {
    e.preventDefault();
    dropArea.classList.remove("dragging");
    dragText.textContent = "Déposez le fichier ici";
    const file = e.dataTransfer.files[0];
    setFile(file);
  };

  let dragText = useRef(null);

  //Met à jour le fichier quand il est séléctionné

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  //affiche le select si la checkbox est coché

  const handleCheckboxChange = () => {
    setSeeUploaded(!seeUploaded);
    setShowFileInput(!showFileInput);
  };


  //Fonction qui va être utilisé si une image doit être upload,
  //elle va utiliser l'API d'imgur pour récupérer un lien et le stocker dans la BDD

  const createImage = async () => {
    try {
      let imageUrl;

      //vérifie si la checkbox est coché, si oui il va récupérer l'id de l'image
      //pour sortir de la fonction

      if (seeUploaded === true) {
        if (selectedImage.length === 0) {
          // Si aucune image n'est sélectionnée, renvoyer null
          return null;
        }
  
        imageUrl = selectedImage.map((id) => image.find((img) => img._id === id)._id);
        return imageUrl;

      } else {
        const response = await axios({
          method: "post",
          url: "https://api.imgur.com/3/image",
          data: formData,
          headers: {
            Authorization: "Client-ID 41e0d680636e63d",
            "Content-Type": "multipart/form-data",
          },
        });

        //récupère l'URL de l'image et poste l'URL de l'image dans la collection

        imageUrl = response.data.data.link;

        const imageResponse = await axios.post(
          `${serverUrl}/image/create`,
          {
            url: imageUrl,
          },
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );

        const imageId = imageResponse.data.image._id;

        return imageId;
      }

    } catch (error) {
      console.log(error);
    }
  };

  formData.append("image", file);

  const handleImageSelect = (event) => {
    event.preventDefault();
    document.getElementById('image').click();
  };

  // va créer une nouvelle enigme dans la BDD

  const handleSubmit = async (e) => {
    e.preventDefault();

    const imageId = await createImage();

    // if (!imageId) {
    //   notify('Erreur lors de la création de l\'image');
    //   return;
    // }


    const postData = {
      title: title,
      image: imageId ? imageId : null,
      desc: description,
      answer: answer,
      text: text,
      indice: indice,
      imageanswer: imageEnigmeId ? imageEnigmeId.value : null,
    };

    console.log(postData);

    try {
      const response = await axios.post(`${serverUrl}/enigme/create`, postData, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (response.status === 200) {
        notify(`${response?.data?.message || ""}`, "success");
      }
    } catch (error) {
      setAccessToken("");
      sessionStorage.removeItem("accessToken");
      notify(`Une erreur est survenue ${error?.response?.data?.message || ""}`, "error");
    }
  };

  useEffect(() => {
    axios.get(`${serverUrl}/image`)
      .then(response => {
        setImage(response.data.Images);
      })
      .catch(error => {
        console.error(error);
      });
  }, [serverUrl]);

  const handleImageEnigmeSelect = (selectedOption) => {
    const selectedImageEnigmeId = selectedOption ? selectedOption : null;
    console.log(`Option selected:`, selectedImageEnigmeId);
    setImageEnigmeId(selectedImageEnigmeId);
  };

  return (
    <>
      <form onSubmit={handleSubmit} className="form-container">
        <div>
          <label htmlFor="title">Titre:</label>
          <input type="text" id="title" value={title} onChange={(e) => setTitle(e.target.value)} />
        </div>
        <div>
          <label htmlFor="description">Enigme:</label>
          <textarea id="description" rows={10} value={description} onChange={(e) => setDescription(e.target.value)} />
        </div>
        <div>
          <label htmlFor="image">Réponse:</label>
          <textarea id="answer" rows={2} value={answer} onChange={(e) => setAnswer(e.target.value.toLowerCase())} />
        </div>
        <div>
          <label htmlFor="text">Texte de la réponse :</label>
          <textarea id="text" rows={10} value={text} onChange={(e) => setText(e.target.value)} />
        </div>
        <div>
          <label htmlFor="indice">Indice:</label>
          <textarea id="indice" rows={10} value={indice} onChange={(e) => setIndice(e.target.value)} />
        </div>
        <div className='select'>
          <div className='checkbox-align'>
            <label htmlFor="image" className='checkbox-align__label'>Choisir une image déjà importé:</label>
            <label htmlFor="checkbox" className='toggle-switch'>
              <input type="checkbox" name="seeUploadedCheckbox" id="seeUploadedCheckbox" className='toggle-input' onClick={handleCheckboxChange} />
              <label htmlFor="seeUploadedCheckbox" className='toggle-label'></label>
            </label>
          </div>
          {showFileInput && (
            <div className="container">
              <div className="card">
                <div
                  className="drop_box"
                  ref={dropArea}
                  onDragOver={handleDragOver}
                  onDragLeave={handleDragLeave}
                  onDrop={handleDrop}
                >
                  <header ref={dragText}>
                    <h4>Déposez votre fichier ici</h4>
                  </header>
                  {file && <p>Le fichier sélectionné est : {file.name}</p>}
                  <input type="file" hidden accept=".png,.jpeg,.gif" id="image" onChange={handleFileChange} />
                  <button className="btn" onClick={handleImageSelect}>Choisir une image pour l'énigme</button>
                </div>
              </div>
            </div>
          )}

          {seeUploaded ? (
            <Select
              className='select'
              isMulti
              value={selectedImage.map((id) => ({
                value: id,
                label: image.find((image) => image._id === id).url
              }))}
              options={image.map((image) => ({
                value: image._id,
                label: (
                  <div>
                    <img src={image.url} alt={image._id} style={{ height: "50px" }} />
                    <span>{image.url}</span>
                  </div>
                )
              }))}
              formatOptionLabel={(option) => option.label}
              onChange={(selectedOptions) =>
                setSelectedImage(selectedOptions.map((option) => option.value))
              }
            />
          ) : null}
        </div>
        <div>
          <label htmlFor="imageEnigme">Image de la réponse:</label>
          <Select
            className="select"
            value={imageEnigmeId.value ? { value: imageEnigmeId.value, label: imageEnigmeId.label } : null}
            options={image.map((img) => ({
              value: img._id,
              label: (
                <div>
                  <img src={img.url} alt={img._id} style={{ height: "50px" }} />
                  <span>{img.url}</span>
                </div>
              )
            }))}
            formatOptionLabel={(option) => option.label}
            onChange={handleImageEnigmeSelect}
          />
        </div>

        <button type="submit">Créer</button>
      </form>
    </>
  );
}

export default EnigmeForm;
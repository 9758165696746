import React from 'react';
import ReactDOM from 'react-dom/client';
import Router from './Router';
import './Scss/main.scss'
import 'react-toastify/dist/ReactToastify.css';


const root = ReactDOM.createRoot(document.getElementById('root'));  
root.render(
 <Router />
);

import React from 'react'
import { useState, useContext } from 'react'
import axios from 'axios'
import API from '../API'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';

export default function Header() {
  const { serverUrl } = useContext(API)
  const [codeInput, setCodeInput] = useState('')
  const [code, setCode] = useState('')
  const [isFlipped, setIsFlipped] = useState(false);
  const [FontAwesome] = useState(FontAwesomeIcon)
  const navigate = useNavigate();

  // Récupère le code entrée par l'utilisateur et vérifie si il existe dans la BDD

  const handleCodeInput = async (event) => {
    event.preventDefault()
    try {
      const response = await axios.get(`${serverUrl}/post/${codeInput}`)
      setCode(codeInput)

      if (response.status === 200) {
        navigate(`/${codeInput}`)
      }

    } catch (error) {
      setCode(null)
    }
  }

  const codeMessage = code === null ? "Le code n'existe pas." : ""

  const handleIconClick = (event) => {
    event.preventDefault();
    setIsFlipped(true);

    setTimeout(() => {  // Après une certaine durée, retour à l'état initial
      setIsFlipped(false);
    }, 1000); // Durée en millisecondes de l'animation
  }

  return (
    <>
      <div className='header'>
        <div className='header__logo'>
          <div className='header__logo__img'></div>
          {/* <h1 className='header__title'>Isla</h1>
          <h2 className='header__subtitle'>Obscura</h2> */}
        </div>
        <div className='header__group'>
        </div>
        <form className='header__form' onSubmit={handleCodeInput}>
          <input className='header__form__input-code' type="text" name="codeInput" id="codeInput" placeholder='Entrez votre code..' value={codeInput} onChange={(event) => setCodeInput(event.target.value.toUpperCase())}
          />
          <button className='header__form__button-submit' onClick={handleCodeInput} type="submit">
            <FontAwesomeIcon icon={faSearch} size="lg" className={`fa-search ${isFlipped ? 'fa-flip' : ''}`} onClick={handleIconClick} />
          </button>
        </form>
        <p className='header__form__error'>{codeMessage}</p>
      </div>
    </>
  )
}
